// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("bootstrap4-toggle/js/bootstrap4-toggle.min")
require("clientjs")
import Routes from '../js-routes.js.erb';
window.Routes = Routes;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'bootstrap'

var splitter = require('split-sms');

var times = {};
var reg_completed = false;

// decision card vars
var current_fs, next_fs, previous_fs; //fieldsets
var left, opacity, scale; //fieldset properties which we will animate
var animating; //flag to prevent quick multi-click glitches

document.addEventListener("turbolinks:load", () => {
    $(document).on('keyup', 'textarea#message', function(_) {
        let split = splitter.split($(event.target).val());
        if (split.parts.length > 1) {
            $('span#msgSplitWarning').html('WARNING: This message will be split across ' + split.parts.length + ' messages! Note, emojis take a ton of space...');
        } else {
            $('span#msgSplitWarning').html('');
        }
    });
    $(document).on('click', 'a[href^="#"]', function (event) {
        event.preventDefault();

        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top
        }, 500);
    });
    // if($('#regModal').length)
    //     $('#regModal').modal({backdrop: 'static', show: true});
    // Probably should be moved to an admin.js sometime
    $('tr.clickable-row-modal').on('click', function(e) {
        if(e.target.nodeName != 'A') {
            $(e.target.parentElement.firstElementChild.firstElementChild).click();
        }
    });

    $('#regModal').on('show.bs.modal', function (event) {
        var button = $(event.relatedTarget);
        var schedule = button.data('schedule');
        var modal = $(this);
        modal.find('#scheduleID').val(schedule);
    });
    $('#regModal').on('hide.bs.modal', function (_) {
        // This refresh shouldn't be needed, but the prayer/question buttons don't seem to be updating...
        Turbolinks.visit(window.href, {action: 'replace'});
    });

    $('#adminDetailModal').on('show.bs.modal', function (event) {
        var button = $(event.relatedTarget);
        var id = button.data('id');
        var name = button.data('name');
        var edit_uri = button.data('edit_uri');

        if(edit_uri === undefined) return;

        $.ajax(edit_uri+'?ajax=true', {success: function (data) {
            modal.find('.modal-body').html(data);
            $('button.roleRevoke').on('click', function () {
                rmRole(this);
            });
            $('button.roleAdd').on('click', function () {
                addRole(this);
            });
        }});

        var modal = $(this);
        modal.find('.modal-title').text('Editing ' + name);
        modal.find('#adminID').val(id);
        modal.find('.adminEditButton').attr('href', edit_uri);
    });

    $('#adminDetailModal').on('hide.bs.modal', function () {
        location.reload();
    });

    // Handle registration
    $(document).on('ajax:success', 'form#reg', (event) => {
        const [res, status, xhr] = event.detail;
        var schedule_id = $('#scheduleID').val();
        $('form#reg').html(xhr.response);
        if(xhr.response.includes('<span id="reg_complete">')) {
            $('a#mainPrayerBtn').data('target', '#prayerRequestModal');
            $('a#mainQuestionBtn').data('target', '#askJohnModal');
            var event = new CustomEvent('auth:login', {detail: schedule_id});
            document.dispatchEvent(event);
        }
    });
    $(document).on('ajax:success', 'form#prayForm', (event) => {
        const [res, status, xhr] = event.detail;
        $('form#prayForm div.form-group').hide();
        $('span#prayerThanks').show();
        setTimeout(function() {
            $('#prayerRequestModal').modal('hide');
            $('span#prayerThanks').hide();
            $('form#prayForm div.form-group').show();
        }, 5000);
    });
    $(document).on('ajax:success', 'form#askForm', (event) => {
        const [res, status, xhr] = event.detail;
        $('form#askForm div.form-group').hide();
        $('span#askThanks').show();
        setTimeout(function() {
            $('#askJohnModal').modal('hide');
            $('span#askThanks').hide();
            $('form#askForm div.form-group').show();
        }, 5000);
    });
    $(document).on('ajax:error', 'form#reg', (event) => {
        const [res, status, xhr] = event.detail;
        console.log(xhr.response);
    });





    // Handle thanks modal
    $(document).on('ajax:success', 'form#msform', (event) => {
        $('#cardThanksModal').modal('show');
        // setTimeout(function() {
        //     $('#cardThanksModal').modal('hide');
        // }, 5000);
    });

    $(document).on('ajax:error', 'form#msform', (event) => {
        const [res, status, xhr] = event.detail;
        console.log(xhr.response);
    });

    $('#cardThanksModal').on('hide.bs.modal', function(e) {
        window.location = "/";
    });




    $('#multiResourceModal').on('show.bs.modal', function(e) {
        var modal = $(this);
        var btn = $(e.relatedTarget);
        var id = btn.data('id');
        var route = Routes.schedule_path(id);
        $.get(route, function (data) {
            modal.find('.modal-body').html(data);
        });
    });

    // Video modal for "archive"
    $('#videoPlayer').on('show.bs.modal', function(e) {
        $('video-js').each(function() {
            if($(this)[0].player !== undefined) {
                $(this)[0].player.pause();
            }
        });
        var btn = $(e.relatedTarget);
        var title = btn.data('title');
        var url = btn.data('url');
        var modal = $(this);
        modal.find('.modal-title').text(title);
        $.get(url, function(data) {
            modal.find('.modal-body').html(data);
        });
    });
    $('#videoPlayer').on('shown.bs.modal', function() {
        $('video-js').each(function() {
            if($(this)[0].player !== undefined) {
                var vid = $($(this)[0]).data('video-id');
                var time = times[vid] - 10;
                if(time !== undefined && time > 0)
                    $(this)[0].player.currentTime(time);
            }
        });
    });
    $('#videoPlayer').on('hide.bs.modal', function() {
        $('video-js').each(function() {
            if($(this)[0].player !== undefined) {
                $(this)[0].player.pause();
                var time = $(this)[0].player.currentTime();
                var vid = $($(this)[0]).data('video-id');
                times[vid] = time;
            }
        });
    });
    setInterval(function() {
        $('video-js').each(function() {
            if($(this)[0].player !== undefined) {
                var time = $(this)[0].player.currentTime();
                var vid = $($(this)[0]).data('video-id');
                var duration = $(this)[0].player.duration();
                if(times[vid] != time) {
                    times[vid] = time;
                    window.pushPosition(vid, Math.floor(duration), Math.floor(time));
                }
            }
        });
    }, 5000);

    // Filter attendance by video
    $('select#video').on('change', function() {
        var vid = $('select#video').val();
        var route = Routes.attendances_path({"video": vid});
        Turbolinks.visit(route, {action: 'replace'});
    });

    // Toggle JS
    $('[data-toggle="tooltip"]').tooltip();
    $('input[data-toggle="toggle"]').bootstrapToggle();
    togglefunc();
    $(function() {
        $('#regTog').change(function() {
            togglefunc();
        })
    });

    // Table row click
    $("td[data-link]").click(function() {
        window.location = $(this).data("link")
    });

    // Bootstrap alert auto close
    $(setTimeout(() => {  $(".alert").alert('close')}, 4000));

    // Autofill video provider based on vid
    $('input#video_vid').blur(function() {
        var str=$('input#video_vid').val();
        var yt_match = str.match(/^(.*?)(^|\/|v=)([a-z0-9_-]{11})(.*)?$/i)
        if(str.match(/^[0-9]+$/) !== null) {
            $('select#video_provider option[value="BrightCove"]').prop('selected', true);
        } else if(yt_match !== null) {
            $('select#video_provider option[value="YouTube"]').prop('selected', true);
            $('input#video_vid').val(yt_match[3]);
        } else if(str != '') {
            alert('Unknown Video ID: ' + str + '\n\nPlease enter a YouTube or BrightCove ID');
        }
    });

    // Clone a div
    jQuery.fn.outerHTML = function() {
        return jQuery('<div />').append(this.eq(0).clone()).html();
    };

    // Append div with row on add new button click
    sections();

    $("#add-domain").click(function(){
        var row = $("div.main-domains").outerHTML();
        row = $(row).css("display", "none");
        $(".series-domains").append(row);
        $(".series-domains").find(".main-domains:last input:text").val('');
        $(".series-domains").find(".main-domains:last").slideDown(200);
    });

    $("#add-guests").click(function(){
        var row = $("div.main-guests").outerHTML();
        row = $(row).css("display", "none");
        $(".series-guests").append(row);
        $(".series-guests").find(".main-guests:last input:text").val('');
        $(".series-guests").find(".main-guests:last").slideDown(200);
    });

    $("#add-btns").click(function(){
        var row = $("div.main-btns").outerHTML();
        row = $(row).css("display", "none");
        $(".series-btns").append(row);
        $(".series-btns").find(".main-btns:last input:text").val('');
        $(".series-btns").find(".main-btns:last").slideDown(200);
    });

    $("#add-times").click(function(){
        var row = $("div.main-times").outerHTML();
        row = $(row).css("display", "none");
        $(".sched-times").append(row);
        $(".sched-times").find(".main-times:last input:text").val('');
        $(".sched-times").find(".main-times:last").slideDown(200);
    });

    $("#add-questions").click(function(){
        var row = $("div.main-questions").outerHTML();
        row = $(row).css("display", "none");
        $(".survey-questions").append(row);
        $(".survey-questions").find(".main-questions:last input:text").val('');
        $(".survey-questions").find(".main-questions:last").slideDown(200);
    });

    $("#add-schedbut").click(function(){
        var row = $("div.schedule-btns").outerHTML();
        row = $(row).css("display", "none");
        $(".main-sched").append(row);
        $(".main-sched").find(".schedule-btns:last input:text").val('');
        $(".main-sched").find(".schedule-btns:last").slideDown(200);
    });

    $(document).on('click', '.add-links', function(){
        var row = $("div.section-btns").outerHTML();
        row = $(row).css("display", "none");
        $(this).parent().siblings(".admin-footer-section").append(row);
        $(this).parent().siblings(".admin-footer-section").find(".section-btns:last input:text").val('');
        $(this).parent().siblings(".admin-footer-section").find(".section-btns:last").slideDown(200);
    });

    $(document).on("click", "#del-schedbut", function(){
        var input = $(this).parents(".schedule-btns").find('input[type="text"]');
        $(this).parents(".schedule-btns").slideUp(200, function() {
            $(input).val('');
        });
    });


    // Delete row on delete button click
    $(document).on("click", "#del-domains", function(){
        var input = $(this).parents(".main-domains").find('input[type="text"]');
        $(this).parents(".main-domains").slideUp(200, function() {
            $(input).val('');
        });
    });

    $(document).on("click", "#del-guests", function(){
        var input = $(this).parents(".main-btns").find('input[type="text"]');
        $(this).parents(".main-guests").slideUp(200, function() {
            $(input).val('');
        });
    });

    $(document).on("click", "#del-btns", function(){
        var input = $(this).parents(".main-btns").find('input[type="text"]');
        $(this).parents(".main-btns").slideUp(200, function() {
            $(input).val('');
        });
    });

    $(document).on("click", ".del-links", function(){
        var input = $(this).parents(".section-btns").find('input[type="text"]');
        $(this).parents(".section-btns").slideUp(200, function() {
            $(input).val('');
        });
    });

    $(document).on("click", ".del-section", function(){
        var input = $(this).parents(".section-wrapper").find('input[type="text"]');
        $(this).parents(".section-wrapper").slideUp(200, function() {
            $(input).val('');
        });
    });

    $(document).on("click", "#del-questions", function(){
        var input = $(this).parents(".main-questions").find('input[type="text"]');
        $(this).parents(".main-questions").slideUp(200, function() {
            $(input).val('');
        });
    });


    $(document).on('change', 'select#country', function() {
        var country = $(this).children('option:selected').val();
        $('input#zip').attr('required', (country == 'US' || country == 'CA'));
    });

    $(document).on("click", "#del-times", function(){
        var input = $(this).parents(".main-times").find('input[type="text"]');
        $(this).parents(".main-times").slideUp(200, function() {
            $(input).val('');
        });
    });


    // scroll control for calender grid

    // $(".moveLeft").click( function(){
    //     $(".calendar-grid").scrollLeft(300);
    // });

    $("#goRight").click( function(){
        $(".calendar-grid").animate({'scrollLeft': "+=205" }, 500);
    });

    $("#goLeft").click( function(){
        $(".calendar-grid").animate({'scrollLeft': "-=205" }, 500);
    });


    // DECISION CARD STUFF

    $(".next").click(function(){
        if(animating) return false;
        animating = true;
        
        current_fs = $(this).parent();
        next_fs = $(this).parent().next();
        
        //activate next step on progressbar using the index of next_fs
        $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");
        
        //show the next fieldset
        next_fs.show(); 
        //hide the current fieldset with style
        current_fs.animate({opacity: 0}, {
            step: function(now, mx) {
                //as the opacity of current_fs reduces to 0 - stored in "now"
                //1. scale current_fs down to 80%
                scale = 1 - (1 - now) * 0.2;
                //2. bring next_fs from the right(50%)
                left = (now * 50)+"%";
                //3. increase opacity of next_fs to 1 as it moves in
                opacity = 1 - now;
                current_fs.css({
            'transform': 'scale('+scale+')',
            'position': 'absolute'
        });
                next_fs.css({'left': left, 'opacity': opacity});
            }, 
            duration: 800, 
            complete: function(){
                current_fs.hide();
                animating = false;
            }, 
            //this comes from the custom easing plugin
            // easing: 'easeInOutBack'
        });
    });

    $(".previous").click(function(){
        if(animating) return false;
        animating = true;
        
        current_fs = $(this).parent();
        previous_fs = $(this).parent().prev();
        
        //de-activate current step on progressbar
        $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");
        
        //show the previous fieldset
        previous_fs.show(); 
        //hide the current fieldset with style
        current_fs.animate({opacity: 0}, {
            step: function(now, mx) {
                //as the opacity of current_fs reduces to 0 - stored in "now"
                //1. scale previous_fs from 80% to 100%
                scale = 0.8 + (1 - now) * 0.2;
                //2. take current_fs to the right(50%) - from 0%
                left = ((1-now) * 50)+"%";
                //3. increase opacity of previous_fs to 1 as it moves in
                opacity = 1 - now;
                current_fs.css({'left': left});
                previous_fs.css({'transform': 'scale('+scale+')', 'opacity': opacity});
            }, 
            duration: 800, 
            complete: function(){
                current_fs.hide();
                animating = false;
            }, 
            //this comes from the custom easing plugin
            // easing: 'easeInOutBack'
        });
    });



    // handles bootstrap front end validation

    var forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    var validation = Array.prototype.filter.call(forms, function(form) {
      form.addEventListener('submit', function(event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });



    // $(".table-col").delay(400).slideDown();
});


function sections() {
    $(".add-section:first").click(function(){
        var row = $("div.section-wrapper").outerHTML();
        row = $(row).css("display", "none");
        console.log(row);
        $(this).parent().siblings(".footer-wrapper").append(row);
        $(this).parent().siblings(".footer-wrapper").find(".section-wrapper:last .section-btns").not(":first").remove();
        $(this).parent().siblings(".footer-wrapper").find(".section-wrapper:last input:text").val('');
        $(this).parent().siblings(".footer-wrapper").find(".section-wrapper:last").slideDown(200);
        // sections();
    });
}

function togglefunc() {
    if(document.getElementById('regTog') != null) {
        var reggy = document.getElementById('regTog').checked;
    } else {
        var reggy = false
    }
    var deffy = document.getElementById('attendTog');

    if(!reggy) {
        $(deffy).bootstrapToggle('off')
        $(deffy).bootstrapToggle('disable');
    } else {
        $(deffy).bootstrapToggle('enable');
    }
}

function addRole(event) {
    var path = $(event).data('url');
    var name = $('meta[name="csrf-param"]').attr('content');
    var token = $('meta[name="csrf-token"]').attr('content');

    $.ajax(path+'&'+name+'='+token, {type: 'PUT', headers: {'X-CSRF-Token': token}, success: function (data) {
        $(event).closest('.modal-body').html(data);
        $('button.roleAdd').on('click', function () {
            addRole(this);
        });
        $('button.roleRevoke').on('click', function () {
            rmRole(this);
        });
    }});
}

function rmRole(event) {
    var path = $(event).data('url');
    var name = $('meta[name="csrf-param"]').attr('content');
    var token = $('meta[name="csrf-token"]').attr('content');

    $.ajax(path+'&'+name+'='+token, {type: 'DELETE', headers: {'X-CSRF-Token': token}, success: function (data) {
        $(event).closest('.modal-body').html(data);
        $('button.roleAdd').on('click', function () {
            addRole(this);
        });
        $('button.roleRevoke').on('click', function () {
            rmRole(this);
        });
    }});
}

window.controlClient = function(act, id) {
    var path = Routes.cnc_path({id: id, format: 'json'});
    $.ajax({
        url: path,
        type: 'PUT',
        contentType: 'application/json',
        data: JSON.stringify({
            act: act
        })
    });
}

window.playVideo = function(id) {
    if(id !== null && id != '') {
        var elem = $('iframe[src*="https://itiswritten.tv/player"][id*="embed"]')[0];
        if(elem !== undefined) {
            console.log('Change player to', id);
            $([document.documentElement, document.body]).animate({
                scrollTop: $(elem).offset().top
            }, 1000);
            var client = new ClientJS();
            var device = {
            'BrowserData': client.getBrowserData(),
            'Browser': client.getBrowser(),
            'BrowserVersion': client.getBrowserVersion(),
            'Engine': client.getEngine(),
            'Device': client.getDevice(),
            'DeviceType': client.getDeviceType(),
            'CPU': client.getCPU(),
            'ScreenPrint': client.getScreenPrint(),
            'ColorDepth': client.getColorDepth(),
            'CurrentResolution': client.getCurrentResolution(),
            'AvailableResolution': client.getAvailableResolution(),
            'DeviceXDPI': client.getDeviceXDPI(),
            'DeviceYDPI': client.getDeviceYDPI(),
            'Plugins': client.getPlugins(),
            'isJava': client.isJava(),
            'JavaVersion': client.getJavaVersion(),
            'isFlash': client.isFlash(),
            'FlashVersion': client.getFlashVersion(),
            'isSilverlight': client.isSilverlight(),
            'SilverlightVersion': client.getSilverlightVersion(),
            'MimeTypes': client.getMimeTypes(),
            'isMimeTypes': client.isMimeTypes(),
            'isFont': client.isFont(),
            'Fonts': client.getFonts(),
            'isLocalStorage': client.isLocalStorage(),
            'isSessionStorage': client.isSessionStorage(),
            'isCookie': client.isCookie(),
            'TimeZone': client.getTimeZone(),
            'Language': client.getLanguage(),
            'SystemLanguage': client.getSystemLanguage(),
            'isCanvas': client.isCanvas()
            }
            var path = Routes.attend_path();
            $.ajax({url: path, type: 'PUT', data: {vid: id, fingerprint: client.getFingerprint(), device: device}, success: function (data) {
                setTimeout(function() {
                    $(elem).attr("src", "https://itiswritten.tv/player?videoId=" + id);
                }, 500);
            }, error: function(xhr, data) {
                console.log(xhr, data);
                if(xhr.responseText == 'Please Register') {
                    $('#regModal').modal('show');
                } else {
                    console.log('show modal');
                }
            }});

        }
    }
}
