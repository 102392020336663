import consumer from "./consumer"

var href_match = /series\/([0-9]*)\/(attendance|viewers)\/(live|\d*)/;

document.addEventListener("turbolinks:load", () => {
  var series = window.location.href.match(href_match);
  if(series !== null) {
    var kind = series[2];
    var viewer = series[3];
    series = series[1];
    var newSub = true;
    $.each(consumer.subscriptions.subscriptions, function(_, sub) {
      var identifier = JSON.parse(sub.identifier);
      if(identifier.channel == 'AttendanceChannel' && identifier.series == series) {
        newSub = false;
      }
    });
    if(newSub) {
      consumer.subscriptions.create({channel: "AttendanceChannel", kind: kind, series: series, viewer: viewer}, {
        connected() {
          // Called when the subscription is ready for use on the server
        },

        disconnected() {
          // Called when the subscription has been terminated by the server
          console.log('Disconnected from', series);
        },

        received(data) {
          // Called when there's incoming data on the websocket for this channel
          if(data.counts !== undefined) {
            $('span#counter').html(data.counts);
            $('span#counterSmall').html(data.counts.replace('Anonymous ', ''));
          }
          if(data.viewer !== undefined && data.time !== undefined && data.video !== undefined) {
            $('td#td' + data.viewer).html(data.time + 's ' + data.video.name);
          }
          if(data.table !== undefined) {
            $('table').html(data.table);
            $("td[data-link]").click(function() {
                window.location = $(this).data("link")
            });
          }
        }
      });
    }
  }
});

document.addEventListener('turbolinks:visit', () => {
  var series = window.location.href.match(href_match);
  $.each(consumer.subscriptions.subscriptions, function(_, sub) {
    var identifier = JSON.parse(sub.identifier);
    if(identifier.channel == 'AttendanceChannel') {
      if(series !== null) {
        series = series[1];
        if(identifier.series != series) {
          consumer.subscriptions.remove(sub);
        }
      } else {
          consumer.subscriptions.remove(sub);
      }
    }
  });
});
