import consumer from "./consumer"

var clientChannel;
var schedule;

function clientSub() {
  var admin = window.location.href.match(/\/admin/);
  if(admin !== null) {
    unSubAll();
  } else {
    var client = new ClientJS();
    clientChannel = consumer.subscriptions.create({channel: "ClientChannel", fingerprint: client.getFingerprint()}, {
      connected() {
        document.dispatchEvent(new Event('socket:connect'));
        var device = {
          'BrowserData': client.getBrowserData(),
          'Browser': client.getBrowser(),
          'BrowserVersion': client.getBrowserVersion(),
          'Engine': client.getEngine(),
          'Device': client.getDevice(),
          'DeviceType': client.getDeviceType(),
          'CPU': client.getCPU(),
          'ScreenPrint': client.getScreenPrint(),
          'ColorDepth': client.getColorDepth(),
          'CurrentResolution': client.getCurrentResolution(),
          'AvailableResolution': client.getAvailableResolution(),
          'DeviceXDPI': client.getDeviceXDPI(),
          'DeviceYDPI': client.getDeviceYDPI(),
          'Plugins': client.getPlugins(),
          'isJava': client.isJava(),
          'JavaVersion': client.getJavaVersion(),
          'isFlash': client.isFlash(),
          'FlashVersion': client.getFlashVersion(),
          'isSilverlight': client.isSilverlight(),
          'SilverlightVersion': client.getSilverlightVersion(),
          'MimeTypes': client.getMimeTypes(),
          'isMimeTypes': client.isMimeTypes(),
          'isFont': client.isFont(),
          'Fonts': client.getFonts(),
          'isLocalStorage': client.isLocalStorage(),
          'isSessionStorage': client.isSessionStorage(),
          'isCookie': client.isCookie(),
          'TimeZone': client.getTimeZone(),
          'Language': client.getLanguage(),
          'SystemLanguage': client.getSystemLanguage(),
          'isCanvas': client.isCanvas()
        }
        clientChannel.send({action: 'metrics', device: device})
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        if(data.eval) {
          eval(data.eval);
        }else if(data.schedule !== undefined) {
          $('section#schedule').html(data.schedule);
          if(schedule !== undefined) {
            $('button#schedule' + schedule + 'Btn').click();
            schedule = undefined
          }
        }
      }
    });
  }
}

function unSubAll() {
  $.each(consumer.subscriptions.subscriptions, function(_, sub) {
    var identifier = JSON.parse(sub.identifier);
    if(identifier.channel == 'ClientChannel') {
      consumer.subscriptions.remove(sub);
    }
  });
}

document.addEventListener('turbolinks:load', () => {
  clientSub();
  window.pushPosition = function(video, duration, position) {
    clientChannel.send({action: 'seek', vid: video, duration: duration, time: position})
  }
});

document.addEventListener('auth:login', (e) => {
  schedule = e.detail;
  unSubAll();
  consumer.disconnect();
  document.addEventListener('socket:connect', () => {
    setTimeout(function() {
      clientChannel.send({action: 'get_schedules'});
    }, 500);
  }, {once: true});
  clientSub();
});
